import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { Container } from "../components/Container"
import { ProjectDetails } from "../components/ProjectDetails"
import ArrowL from "../assets/arrow_left.svg"
import ArrowR from "../assets/arrow_right.svg"

const ProjectPage = props => {
  const { project, prevPath, nextPath } = props.pageContext
  const navLinkClasses = "w-1/2 block text-secondary-light"
  return (
    <section className="min-h-screen flex flex-col justify-between">
      <SEO title="Project" />
      <Header pageHeader="Portfolio" />
      <section>
        <Container>
          <ProjectDetails pData={project} />
          <div className="flex justify-center">
            <Link
              className={navLinkClasses}
              to={prevPath}
            >
              <ArrowL className="h-24 w-24 fill-current m-auto" />
            </Link>
            <Link
              className={navLinkClasses}
              to={nextPath}
            >
              <ArrowR className="h-24 w-24 fill-current m-auto" />
            </Link>
          </div>
        </Container>
      </section>    
      <Footer />
    </section>
  )
}

export default ProjectPage
