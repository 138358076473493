import React from "react"

import { Image } from "../components/Image"
import ViewIcon from "../assets/view.svg"
import CodeIcon from "../assets/code.svg"

export const ProjectDetails = ({ pData }) => {
  const linkClassName = "w-10 h-10 text-primary fill-current mr-4"

  return (
    <div className="flex flex-col justify-between md:flex-row md:mb-20">
      <div className="py-2 w-full h-full md:w-1/2">
        <Image
          filterByText={pData.pImg}
          className="relative inset-0 h-full w-full"
          imgClassName="absolute inset-0 h-auto w-full"
        />
      </div>      
      <div className="mb-4 flex flex-col justify-center md:mx-4 md:mb-0 md:w-1/2">
      <h1 className="text-2xl font-serif font-bold py-2 text-center md:text-4xl">
        {pData.pName}
      </h1>
        <p className="py-2 italic text-center text-sm mb-2 md:text-lg">{pData.pDesc}</p>

        <div className="px-2 py-4 text-sm flex flex-wrap items-center justify-center bg-tertiary">
          {pData.pTech.map((tech, index) => (
            <div key={pData.pName + tech} className="flex items-center">
              <div className="capitalize pr-2">{tech}</div>
              {index !== pData.pTech.length - 1 ? (
                <p className="pr-2 text-sm font-bold text-primary">+</p>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
        <div id="project-actions" className="py-2 flex justify-center">
          <a href={pData.pLink} target="_blank" rel="noopener noreferrer" title='view project'>
            <ViewIcon className={linkClassName} />
          </a>
          <a href={pData.pCode} target="_blank" rel="noopener noreferrer"  title='view code'>
            <CodeIcon className={linkClassName}/>
          </a>
        </div>
      </div>
    </div>
  )
}
